import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Content from "../../../../content/produk/controller/videoprocessor/NOVASTAR VX4S/Product-Page.json";
import Datasheet from "../../../../content/produk/controller/videoprocessor/NOVASTAR VX4S/datasheet-novastar-vx4s.pdf";
import ProductImage from "../../../../images/product/controller/videoprocessor/NOVASTAR VX4S/Product-Summary-Novastar-VX4s.jpg";
const novastarvx4s = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>Video Processor Novastar VX4S</h1>
                </div>
                <Row>
                    <Col>
                        <Image src={ProductImage} fluid alt={"Gambar modul"} />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className={"section-title"}>
                            <h2>Product Summary</h2>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                            VX4S adalah controller layar LED profesional. Selain
                            berfungsi sebagai kendali layar, VX4S juga memiliki
                            processor front-end yang bertenaga sehingga skalar
                            eksternal tidak diperlukan lagi. Dengan kualitas
                            gambar yang sempurna dan kendali gambar yang
                            fleksibel memenuhi kebutuhan industri penyiaran.
                        </p>
                        <ul style={{ textAlign: "justify" }}>
                            <li>
                                The inputs of the VX4 include CVBS×3, VGA×3,
                                DVI×2, HDMI×1 , DP×1. They support input
                                resolution up to 1920×1200@60Hz; the input
                                images of VX4 can be zoomed point-to-point
                                according to the screen resolution;
                            </li>
                            <li>
                                Provide seamless high-speed switch and fade-in/
                                fade-out effect so as to strengthen and display
                                picture demonstration of professional quality;
                            </li>
                            <li>
                                The location and size of PIP can both be
                                adjusted, which can be controlled at will;
                            </li>
                            <li>
                                Adopts the Nova G4 engine; the screen is stable
                                and flicker free without scanning lines; the
                                images are exquisite and have a good sense of
                                depth;
                            </li>
                            <li>
                                Can implement white balance calibration and
                                color gamut mapping based on different features
                                of LEDs used by screens to ensure reproduction
                                of true colors;
                            </li>
                            <li>HDMI/external audio input;</li>
                            <li>10bit/8bit HD video source;</li>
                            <li>The loading capacity: 2.3 million pixel;</li>
                            <li>
                                Support multiple controller montage for loading
                                huge screen;
                            </li>
                            <li>
                                Supports Nova's new-generation point-by-point
                                correction technology; the correction is fast
                                and efficient;
                            </li>
                            <li>
                                Computer software for system configuration is
                                not necessary. The system can be configured
                                using one knob and one button. All can be done
                                just by fingers. That's what we called Touch
                                Track!
                            </li>
                            <li>
                                Adopts an innovative architecture to implement
                                smart configuration; the screen debugging can be
                                completed within 30 seconds; greatly shorten the
                                preparation time on the stage;
                            </li>
                            <li>
                                A intuitive LCD display interface and clear
                                button light hint simplify the control of the
                                system;
                            </li>
                        </ul>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

novastarvx4s.propTypes = {
    siteTitle: PropTypes.string,
};

novastarvx4s.defaultProps = {
    siteTitle: ``,
};
export default novastarvx4s;
